<template>
    <div class="container px-1 py-5">
        <div class="flex items-center justify-between mb-5 md:mb-10">
            <div>
                <h1 class="lg:text-2xl text-xl" v-if="sn.part_number?.make || sn.part_number?.model">
                    {{ sn.part_number.make }} {{ sn.part_number.model }}
                </h1>
                <h2 class="text-slate-500 text-sm">S/N: {{ sn.sn }}</h2>
            </div>

            <div class="shrink-0" v-if="!floatSave">
                <c-button class="bg-slate-200 mr-2 text-slate-500" router
                          v-bind:to="{ name : 'devices.pn.edit' }"
                >
                    {{ __('Back') }}
                </c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" dusk="save-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
            <c-button class="bg-success fixed right-5 rounded-full top-5 z-10" v-else v-on:click="submit">
                <c-icon class="h-6 stroke-white w-6" icon="save" />
            </c-button>
        </div>

        <div class="flex flex-col lg:flex-row items-start">
            <div class="flex flex-col lg:mr-4 lg:w-2/3 w-full">
                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('General information') }}</h2>

                    <div class="grid lg:grid-cols-2 gap-10 mb-10">
                        <c-control-text name="sn" required
                                        v-bind:invalid="!!requestStore.errors?.sn"
                                        v-bind:label="__('Serial number (S/N)')"
                                        v-model="sn.sn"
                        />

                        <c-control-text name="name"
                                        v-bind:invalid="!!requestStore.errors?.name"
                                        v-bind:label="__('Local name')"
                                        v-model="sn.name"
                        />
                    </div>

                    <div class="grid lg:grid-cols-2 gap-10">
                        <c-control-autocomplete icon="search" label-field="name" name="partnpartner"
                                                v-bind:invalid="!partner && !!requestStore.errors?.partner_site_id"
                                                v-bind:label="__('Partner')"
                                                v-bind:placeholder="__('Search by partner name or tax ID')"
                                                v-bind:source-get="partnerGet"
                                                v-bind:source-search="partnerSearch"
                                                v-model="sn.partner"
                        />

                        <c-control-select name="ticket.site"
                                          v-bind:invalid="!!requestStore.errors?.partner_site_id"
                                          v-bind:label="__('Site')"
                                          v-bind:options="sites"
                                          v-if="sn.partner"
                                          v-model="sn.partner_site_id"
                        />
                    </div>
                </div>

                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <div class="flex mb-5 flex-col md:flex-row md:items-center">
                        <h2 class="font-medium mr-10 text-lg">{{ __('Maintenance interval') }}</h2>

                        <span class="flex items-center text-error text-sm"
                              v-if="sn.needs_maintenance"
                        >
                            <c-icon class="h-6 mr-2 stroke-error w-6" icon="alert-triangle" />
                            {{ __('Needs maintenance.') }}
                        </span>
                    </div>

                    <div class="grid lg:grid-cols-3 gap-10 items-end mb-10">
                        <c-control-date icon="calendar"
                                        icon-position="left"
                                        name="last_maintenance_at"
                                        v-bind:enable-time-picker="false"
                                        v-bind:format="localizationStore.dateFormat"
                                        v-bind:invalid="!!requestStore.errors?.last_maintenance_at"
                                        v-bind:label="__('Last maintenance date')"
                                        v-model="sn.last_maintenance_at"
                        />

                        <c-control-number name="last_maintenance_usage"
                                        v-bind:invalid="!!requestStore.errors?.last_maintenance_usage"
                                        v-bind:label="__('Last maintenance usage')"
                                        v-model="sn.last_maintenance_usage"
                        />

                        <c-button class="bg-slate-100 group hover:bg-highlight hover:text-white py-2 text-highlight"
                           v-on:click="clearMaintenanceInterval"
                        >
                            <c-icon class="group-hover:stroke-white h-5 mr-2 stroke-highlight w-5" icon="check" />
                            {{ __('Clear maintenance interval') }}
                        </c-button>
                    </div>

                    <c-control-number name="current_usage"
                                    v-bind:invalid="!!requestStore.errors?.current_usage"
                                    v-bind:label="__('Current usage')"
                                    v-model="sn.current_usage"
                    />
                </div>

                <div class="bg-white p-2 rounded shadow-sm" v-if="fields.length > 0 && sn.fieldValues">
                    <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>

                    <template v-for="field in fields">
                        <div class="mt-5">
                            <div class="bg-indigo-50 mb-1 p-2 rounded"
                                 v-for="repetition in computeFieldRepetitions(field)"
                                 v-if="field.fields && field.fields.length > 0"
                            >
                                <div class="flex items-center justify-between mb-4">
                                    <strong class="text-sm">{{ field.label }}</strong>
                                    <a class="cursor-pointer"
                                       v-if="repetition > 1"
                                       v-on:click="deleteFieldRepetition(field, repetition)"
                                    >
                                        <c-icon class="h-6 stroke-error w-6" icon="trash-2" />
                                    </a>
                                </div>
                                <div class="mb-5"
                                     v-for="subfield in field.fields"
                                >
                                    <custom-field v-bind:field="subfield"
                                                  v-bind:id="field.key + '.' + (repetition-1) + '.' + subfield.key"
                                                  v-model="sn.fieldValues[field.key + '.' + (repetition-1) + '.' + subfield.key]"
                                    />
                                </div>
                                <a class="bg-highlight cursor-pointer ml-auto p-1 rounded table"
                                   v-if="field.repeatable && repetition === computeFieldRepetitions(field)"
                                   v-on:click="addFieldRepetition(field)"
                                >
                                    <c-icon class="h-6 stroke-white w-6" icon="plus" />
                                </a>
                            </div>
                            <custom-field v-bind:field="field"
                                          v-bind:id="field.key"
                                          v-else
                                          v-model="sn.fieldValues[field.key]"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <div class="lg:w-1/3 w-full">
                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('QR code') }}</h2>

                    <div class="flex items-center">
                        <img class="mr-5 w-1/3" v-bind:src="'data:image/png;base64,' + sn.qr" v-if="sn.qr" />

                        <c-button class="bg-slate-100 group hover:bg-highlight hover:text-white py-2 text-highlight"
                                  v-bind:to="'/qr/download/' + sn.qr_encoded + '?filename=' + sn.sn + '.pdf'"
                        >
                            <c-icon class="group-hover:stroke-white h-5 mr-2 stroke-highlight w-5" icon="printer" />
                            {{ __('Print') }}
                        </c-button>
                    </div>
                </div>

                <div class="bg-white p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Service history') }}</h2>

                    <router-link class="border-b border-slate-200 flex hover:bg-slate-50 items-center last:border-b-0 px-1 py-2"
                                 v-bind:to="{
                                    name : sessionStore.user?.tenant.config.toggle_worksheet_edit_allowed
                                                ? 'worksheets.edit'
                                                : 'worksheets.show',
                                    params: {
                                        worksheet : worksheet.id
                                    }
                                }"
                                 v-for="worksheet in sn.worksheets"
                                 v-if="sn.worksheets && sn.worksheets.length > 0"
                    >
                        <div class="grow shrink-0">
                            <span class="block font-bold mb-2 text-sm">
                                {{ DateTime.fromISO(worksheet.signed_at).toFormat(localizationStore.dateFormat) }}
                            </span>
                            <div class="text-slate-500 text-xs">
                                <img class="h-5 inline mr-1 -mt-1 rounded w-5"
                                     v-bind:src="worksheet.ticket.assignee.avatar"
                                     v-bind:alt="worksheet.ticket.assignee.name"
                                />
                                {{ worksheet.ticket.assignee.name }}
                            </div>
                        </div>
                        <c-icon class="h-8 shrink-0 stroke-slate-800 w-8" icon="chevron-right" />
                    </router-link>

                    <p class="text-slate-500 text-sm" v-else>{{ __('This device was never serviced before.') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CControlAutocomplete,
        CControlDate,
        CControlNumber,
        CControlSelect,
        CControlText,
        CIcon,
    } from '@teamfurther/cinderblock';
    import { DateTime } from 'luxon';

    import PartnerRepository from '../../../repositories/PartnerRepository';
    import SerialNumberRepository from '../../../repositories/SerialNumberRepository';
    import { useRequestStore } from '../../../stores/request';
    import { useLocalizationStore } from '../../../stores/localization';
    import { useSessionStore } from '../../../stores/session';

    import CustomField from '../../../components/CustomField.vue';

    const __ = inject('__');
    const fields = ref<Object[]>([]);
    const floatSave = ref<boolean>(false);
    const localizationStore = useLocalizationStore();
    const partner = ref<object>(null);
    const partnerRepository = PartnerRepository.getInstance();
    const route = useRoute();
    const serialNumberId = route.params.sn;
    const sessionStore = useSessionStore();

    const sn = ref<object>({
        name: '',
        partner_site_id: '',
        sn : '',
    });

    const requestStore = useRequestStore();
    const serialNumberRepository = SerialNumberRepository.getInstance();
    const sites = ref<Object[]>();


    function addFieldRepetition(field) {
        const currentRepetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            sn.value.fields.push({
                key: field.key + '.' + currentRepetitions + '.' + subfield.key,
                value: null,
                serial_number_id: sn.value.id,
            });
        });

        computeSnFieldValues();
    }

    async function clearMaintenanceInterval() {
        sn.value.last_maintenance_at = DateTime.now().toISODate();
        sn.value.last_maintenance_usage = sn.value.current_usage;

        await submit();
    }

    function computeFieldRepetitions(field) {
        let repetitions = null;

        sn.value.fields.forEach(f => {
            const keyParts = f.key.split('.');

            if (keyParts[0] === field.key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    function computeSnFieldValues() {
        fields.value.forEach(field => {
            if (field.fields) {
                field.fields.forEach(subfield => {
                    const found = sn.value.fields.findIndex(f => {
                        return f.key === field.key + '.0.' + subfield.key;
                    });

                    if (found === -1) {
                        sn.value.fields.push({
                            key: field.key + '.0.' + subfield.key,
                            value: null,
                            serial_number_id: sn.value.id,
                        });
                    }
                });
            } else {
                const found = sn.value.fields.findIndex(f => {
                    return f.key === field.key;
                });

                if (found === -1) {
                    sn.value.fields.push({
                        key: field.key,
                        value: null,
                        serial_number_id: sn.value.id,
                    });
                }
            }
        });

        sn.value.fieldValues = sn.value.fields.reduce((accumulator, field) => {
            accumulator[field.key] = field.value;

            return accumulator;
        }, {});
    }

    function deleteFieldRepetition(field, repetition) {
        const repetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            sn.value.fields = sn.value.fields.filter(f => {
                return f.key !== field.key + '.' + (repetition - 1) + '.' + subfield.key;
            });
        });

        for (let i = repetition; i < repetitions; i++) {
            field.fields.forEach(subfield => {
                let subject = sn.value.fields.findIndex(f => {
                    return f.key === field.key + '.' + i + '.' + subfield.key;
                });

                sn.value.fields[subject].key = field.key + '.' + (i - 1) + '.' + subfield.key;
            });
        }

        computeSnFieldValues();
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            },
            include: ['sites']
        });
    }

    async function submit() {
        sn.value = await serialNumberRepository.update(serialNumberId,
            sn.value,
            {
                include: [
                    'fields',
                    'site',
                    'site.partner',
                    'site.partner.sites',
                    'worksheets',
                    'worksheets.ticket',
                    'worksheets.ticket.assignee',
                ]
            }
        );

        computeSnFieldValues();

        sn.value.partner = sn.value.site?.partner ?? null;
    }

    onMounted(async () => {
        await requestStore.get('devices/sn/fields').then(async (response : any) => {
            fields.value = response.data;
        });

        sn.value = await serialNumberRepository.show(serialNumberId, {
            append: ['qr', 'qr_encoded'],
            include: [
                'fields',
                'site',
                'site.partner',
                'site.partner.sites',
                'worksheets',
                'worksheets.ticket',
                'worksheets.ticket.assignee',
            ]
        });

        computeSnFieldValues();

        sn.value.partner = sn.value.site?.partner ?? null;

        window.addEventListener('scroll', () => {
            floatSave.value = window.scrollY > 100;
        });
    });

    watch(() => sn.value, () => {
        Object.keys(sn.value.fieldValues).forEach(key => {
            let field = sn.value.fields.find(f => f.key === key);

            if (field) {
                field.value = sn.value.fieldValues[key];
            }
        });
    }, { deep: true });

    watch(() => sn.value?.partner, () => {
        sn.value.partner_id = sn.value.partner?.id ?? null;

        if (sn.value.partner_id) {
            sites.value = sn.value.partner?.sites.map((site) => {
                return {
                    label: site['name'],
                    value: site['id'],
                }
            });
        } else {
            sites.value = [];
            sn.value.partner_site_id = null;
        }
    });

    watch(() => sn.value, () => {
        Object.keys(sn.value.fieldValues).forEach(key => {
            let field = sn.value.fields.find(f => f.key === key);

            if (field) {
                field.value = sn.value.fieldValues[key];
            }
        });
    }, { deep: true });
</script>
